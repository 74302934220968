import React from 'react';
import NavComponent from './components/NavComponent';

const AboutUs = () => {
  return (
    <>
      <NavComponent></NavComponent>
      <div>
        <h1>About Us</h1>
        <p>This is the About Us page thi</p>
      </div>
    </>
  );
};

export default AboutUs;