import React, { useState, useEffect } from 'react';
import NavComponent from '../../components/NavComponent';
import { Container, Row, Col, Card } from 'react-bootstrap';
import menuItemImage from '../../assets/images/menuItem.png';
import './menuItemStyle.css';

import axios from 'axios';
const MenuItems = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const config = {
            headers: {
              'lang': 'ar',
            }
        };
        // Fetch data with axios
        axios.get('https://dashboard.lali.com.sa/api/menu-items',config)
        .then((response) => {
            setData(response.data); // Set data
        });
    }, []);

  return (
    <>
        <NavComponent></NavComponent>
        <Container>
            <Row>
                {
                    data.map(item => (
                        <div className='col-md-4'>
                            <div className='card'>
                                <div className='menuItemPhoto'>
                                    <img src={item.photo} />
                                    <span>متاح الآن</span>
                                </div>
                                <div className='menuItemDetails'>
                                    <div className='menuItemDetailsTitles'>
                                        <h5>مشروبات</h5>
                                        <h2>{item.name}</h2>
                                    </div>
                                    <div className='menuItemDetailsPrice'>
                                        {item.price} ر.س
                                    </div>
                                </div>
                                <div className='menuItemDetailsDescription'>
                                    <p>مميز بقطع الكيوي المميزة</p>
                                </div>
                                <a className='menuItemLink'>
                                    اضف للسلة
                                </a>
                            </div>
                        </div>
    
                    // <li key={item.id}>{item.name}</li>
                    ))
                }
            </Row>
        </Container>
    </>
  );
};

export default MenuItems;