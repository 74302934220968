import React from 'react';
import NavComponent from '../../components/NavComponent';

const ContactUs = () => {
  return (
    <>
        <NavComponent></NavComponent>
        <div>
            <h1>Contact Us</h1>
            <p>This is the Contact Us page thi</p>
        </div>
    </>
  );
};

export default ContactUs;