import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from '../About';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const NavComponent = () => {
  return (
    <Navbar bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="#home">Navbar</Navbar.Brand>
        <Nav className="me-auto">
          <li>
            <Link to="/" class="nav-link">Home</Link>
          </li>
          <li>
            <Link to="/about" class="nav-link">About Us</Link>
          </li>
          <li>
            <Link to="/items" class="nav-link">Menu Items</Link>
          </li>
          <li>
            <Link to="/contactus" class="nav-link">Contact Us</Link>
          </li>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavComponent;